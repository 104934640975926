import breadcrumbComp from "../../common/breadcrumb-comp.vue";
import EncryptUtility from "../../utility/js/encrypt-utility";
import AddFavourite from "../../utility/js/addFav";
import BackToTop from "../../common/back-to-top.vue";
import reportsService from "./reports-service";
import exportLimit from "../../common/export-limit.vue";
import moment from "moment";
import Utility from "../../../shared/utility";
import html2pdf from "html2pdf.js";
export default {
  data() {
    return {
      userId: EncryptUtility.localStorageDecrypt("userID"),
      actionList: [],
      favouriteList: [],
      mainPage: "",
      subPage: "",
      backSlash: true,
      pageUrl: "",
      showAdd: false,
      refresh: true,
      selectedProject: "",
      projectData: [],
      showBusiness: false,
      selectedBusiness: "",
      businessData: [],
      disableRun: false,
      showData: false,
      totalRecords: 0,
      exportDisable: false,
      printDisabled: false,
      excelName: "",
      searchBTOStatus: "",
      openBTOItems: [],
      apiData: [],
      disableDrop: false,
      export50kData: false,
      exportDialog: false,
      projectVal: "",
      businessVal: "",
      headersOpenBTO: [
        { text: "SO#", value: "SO#", class: "primary customwhite--text" },
        { text: "BTO#", value: "BTO#", class: "primary customwhite--text" },
        { text: "BTO Status", value: "BTO Status", class: "primary customwhite--text" },
        { text: "Part Number", value: "Part Number", class: "primary customwhite--text" },
        { text: "Description", value: "Description", class: "primary customwhite--text" },
        { text: "Part Type", value: "Part Type", class: "primary customwhite--text" },
        { text: "Class", value: "Class", class: "primary customwhite--text" },
        { text: "Required Quantity", value: "Req Qty", class: "primary customwhite--text" },
        { text: "Issued", value: "Issued", class: "primary customwhite--text" },
        { text: "FGI", value: "FGI", class: "primary customwhite--text" },
        { text: "Allocation", value: "Aloc", class: "primary customwhite--text" },
        { text: "Backlog", value: "Backlog", class: "primary customwhite--text" },
        { text: "On PO", value: "On PO", class: "primary customwhite--text" },
        { text: "Warehouse", value: "Warehouse", class: "primary customwhite--text" },
        { text: "Created", value: "Created", class: "primary customwhite--text" },
        { text: "Request", value: "Request", class: "primary customwhite--text" },
      ],
      json_fields: {
        "SO#": "SO#",
        "BTO#": "BTO#",
        "BTO Status": "BTO Status",
        "Part Number": "Part Number",
        Description: "Description",
        "Part Type": "Part Type",
        Class: "Class",
        "Required Quantity": "Req Qty",
        Issued: "Issued",
        FGI: "FGI",
        Allocation: "Aloc",
        Backlog: "Backlog",
        "On PO": "On PO",
        Warehouse: "Warehouse",
        Created: "Created",
        Request: "Request",
      },
    };
  },
  async created() {
    this.route = this.$route.path.replace("/", "");
    let data = await AddFavourite.getPageAction(this.userId, this.route);
    this.actionList = data.actionList;
    this.mainPage = data.mainPage;
    this.favouriteList = data.favouriteList;
    this.pageUrl = data.pageUrl;
    this.subPage = data.subPage;
    this.showAdd = data.showAdd;
    this.getProjectData();
  },
  methods: {
    //Reset Function
    resetFunction() {
      this.selectedProject = "";
      this.projectData = [];
      this.showBusiness = false;
      this.selectedBusiness = "";
      this.businessData = [];
      this.disableRun = false;
      this.showData = false;
      this.totalRecords = 0;
      this.exportDisable = false;
      this.printDisabled = false;
      this.excelName = "";
      this.searchBTOStatus = "";
      this.openBTOItems = [];
      this.disableDrop = false;
      this.export50kData = false;
      this.exportDialog = false;
      this.projectVal = "";
      this.businessVal = "";
      this.getProjectData();
    },
    //Get Project data on page load
    async getProjectData() {
      this.apiData = [];
      this.apiData = await reportsService.openPromptBTOStatusReport("get", this.userId);
      if (this.apiData.message !== "NA") {
        this.projectData = this.apiData;
      }
    },
    //Project Selected
    projectSelected() {
      this.businessData = this.apiData.filter((obj) => obj.proj_key == this.selectedProject);
      const projectName = this.apiData.find((obj) => obj.proj_key == this.selectedProject);
      this.projectVal = projectName.project;
      this.showBusiness = true;
    },
    //Business Selected
    businessSelected() {
      this.disableRun = true;
      const businessName = this.apiData.find((obj) => obj.bu_key == this.selectedBusiness);
      this.businessVal = businessName.business;
    },
    //Run Report
    async runReport() {
      let data = await reportsService.openBTOStatusReport(this.selectedProject, this.selectedBusiness, "get");
      if (data.message !== "NA") {
        const newArr = data.map((obj) => {
          return {
            ...obj,
            Created: obj.Created !== undefined ? moment(obj.Created).format("MM/DD/YYYY") : "",
            Request: obj.Request !== undefined ? moment(obj.Request).format("MM/DD/YYYY") : "",
          };
        });
        this.openBTOItems = newArr;
        this.totalRecords = data.length;
        this.disableDrop = true;
        this.showData = true;
      } else {
        this.openBTOItems = [];
        this.totalRecords = 0;
        this.disableDrop = false;
        this.showData = false;
      }
    },
    //Export Data using component
    async exportData() {
      this.export50kData = true;
      this.exportDialog = false;
    },
    //To export the Excel file
    async exportExcel() {
      if (this.totalRecords <= 15000) {
        this.excelName =
          "Open_BTO_Status_" + new Date().toISOString().replace("T", "_").replace("Z", "").replace(".", "").replaceAll("-", "_") + ".xls";
        const response = await this.axios.get(`/rt/open_bto_status_report?proj_id=${this.selectedProject}&bu_id=${this.selectedBusiness}`);
        let responseData = JSON.parse(response.data.body.message);
        const newArr = responseData.map((obj) => {
          return {
            ...obj,
            Created: obj.Created !== undefined ? moment(obj.Created).format("MM/DD/YYYY") : "",
            Request: obj.Request !== undefined ? moment(obj.Request).format("MM/DD/YYYY") : "",
          };
        });
        this.export50kData = false;
        return newArr;
      } else {
        this.exportDialog = true;
        this.finishDownload();
        return true;
      }
    },
    //Start the Loader when excel process starts
    startDownload() {
      let LoaderDialog = {
        visible: true,
        title: "Please Wait...",
      };
      this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
    },
    //Wnd the Loader when the process is completed
    finishDownload() {
      let LoaderDialog = {
        visible: false,
        title: "Please Wait...",
      };
      this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
    },
    //Export pdf
    async printDetails() {
      this.startDownload();
      const response = await this.axios.get(`/rt/open_bto_status_report?proj_id=${this.selectedProject}&bu_id=${this.selectedBusiness}`);
      let responseData = JSON.parse(response.data.body.message);
      const newArr = responseData.map((obj) => {
        return {
          ...obj,
          Created: obj.Created !== undefined ? Utility.convertESTToLocalOnlyDate(obj.Created) : "",
          Request: obj.Request !== undefined ? Utility.convertESTToLocalOnlyDate(obj.Created) : "",
        };
      });
      newArr.forEach((item) => {
        delete item["Project"];
        delete item["Business"];
        delete item["Sub-Business"];
      });
      this.totalLotDetails = [];
      this.totalLotDetails = this.buildHtmlTable(newArr);
      this.pdfName =
        "Open_BTO_Status_Report" + new Date().toISOString().replace("T", "_").replace("Z", "").replace(".", "").replaceAll("-", "_") + ".pdf";
      html2pdf(this.totalLotDetails, {
        margin: 0.5,
        filename: this.pdfName,
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { dpi: 190, letterRendering: true },
        jsPDF: { unit: "in", format: "a0", orientation: "landscape" },
      });
      this.finishDownload();
    },
    buildHtmlTable(response) {
      this.tblTag = document.createElement("table");
      this.trTag = document.createElement("tr");
      this.thTag = document.createElement("th");
      this.tdTag = document.createElement("td");
      let table = this.tblTag.cloneNode(false);
      table.setAttribute("cellpadding", "0");
      table.setAttribute("cellspacing", "0");
      table.style.border = "1px solid black";
      table.style.borderRight = "none";
      table.style.borderTop = "none";
      let columns = this.addAllColumnHeaders(response, table);
      columns = columns.filter((column) => column !== "tid");
      for (var i = 0, maxi = response.length; i < maxi; ++i) {
        var tr = this.trTag.cloneNode(false);
        for (var j = 0, maxj = columns.length; j < maxj; ++j) {
          var td = this.tdTag.cloneNode(false);
          td.style.border = "1px solid black";
          td.style.padding = "10px";
          td.style.borderLeft = "none";
          td.style.borderBottom = "none";
          var cellValue = response[i][columns[j]];
          td.appendChild(document.createTextNode(cellValue || ""));
          tr.appendChild(td);
        }
        table.appendChild(tr);
      }
      return table;
    },
    addAllColumnHeaders(response, table) {
      let columnSet = [];
      let tr = this.trTag.cloneNode(false);
      for (var i = 0, l = response.length; i < l; i++) {
        for (var key in response[i]) {
          if (columnSet.indexOf(key) === -1) {
            if (key != "tid") {
              columnSet.push(key);
              var th = this.thTag.cloneNode(false);
              th.style.border = "1px solid black";
              th.setAttribute("padding", "5px");
              th.style.borderLeft = "none";
              th.style.borderBottom = "none";
              th.style.backgroundColor = "#005ba8";
              th.style.color = "white";
              if (key == "SO#") key = "SO#";
              if (key == "BTO#") key = "BTO#";
              if (key == "BTO Status") key = "BTO Status";
              if (key == "Part Number") key = "Part Number";
              if (key == "Description") key = "Description";
              if (key == "Part Type") key = "Part Type";
              if (key == "Class") key = "Class";
              if (key == "Req Qty") key = "Required Quantity";
              if (key == "Issued") key = "Issued";
              if (key == "FGI") key = "FGI";
              if (key == "Aloc") key = "Allocation";
              if (key == "Backlog") key = "Backlog";
              if (key == "On PO") key = "On PO";
              if (key == "Warehouse") key = "Warehouse";
              if (key == "Created") key = "Created";
              if (key == "Request") key = "Request";
              th.appendChild(document.createTextNode(key));
              tr.appendChild(th);
            }
          }
        }
      }
      table.appendChild(tr);
      return columnSet;
    },
    //To add and remove From Fav
    addFav() {
      this.showAdd = !this.showAdd;
      AddFavourite.add_remove_Favourite(this.showAdd, this.userId, this.route);
    },
  },
  components: {
    breadcrumbComp,
    BackToTop,
    exportLimit,
  },
};
